
@import "../plugin/thesaas/scss/theme";

@font-face {
  font-family: 'Photoshoot';
  src: url('#{$fonts-dir}Photoshoot.otf');
  font-weight: normal;
  font-style: normal;
}

// Write your custom style. Feel free to split your code to several files
body {
  color: $color-text-darker;
  font-size: 1rem;
}

.spinner {
  -webkit-transition:all 0.5s ease-out;
  -moz-transition:all 0.5s ease-out;
  -ms-transition:all 0.5s ease-out;
  -o-transition:all 0.5s ease-out;
  transition:all 0.5s ease-out;

  &:hover {
    -webkit-transform:rotate(15deg);
    -moz-transform:rotate(15deg);
    -ms-transform:rotate(15deg);
    -o-transform:rotate(15deg);
    transform:rotate(15deg);
  }
}

img.center {
  display: block;
  margin: auto
}

@media (min-width: 992px) {
  .home .card {
    flex-direction: row;
  }
}

.card-title-home {
  font-weight: bold;
  text-transform: uppercase;
}

.program-logo {
  max-width: 450px;
  width: 100%
}

.zoom:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.06); 
}

.stretch-cards {
  justify-content: stretch;
}

.stretch-cards .card {
  height: 100%;
}

.phrase-title h1 {
  font-family: 'Photoshoot';
}

img.family-title {
  max-height: 200px;
}